import React, { FunctionComponent, useEffect, useState } from "react";
import { Carousel, Row, Col } from "react-bootstrap";
import { apiService, Countdown as CountdownType } from "../../utils/ApiService";
import { BaseScreen } from "../base-screen/BaseScreen";
import Countdown from "react-countdown";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import "./HomeScreen.scss";

export const HomeScreen: FunctionComponent = () => {
  const [showModal, setModalShown] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [quillValue, setQuillValue] = useState("");
  const [modalText, setModalText] = useState("");
  const [countdowns, setCountdowns] = useState<CountdownType[]>([]);

  const handleModal = () => {
    setModalShown(!showModal);
  };

  useEffect(() => {
    apiService
      .getCountdowns()
      .then((countdowns) => {
        setCountdowns(countdowns);
      })
      .catch((err) => {
        setModalText("Error fetching countdowns");
        setModalTitle("Error");
        setModalShown(true);
        setCountdowns([]);
      });
    apiService
      .getQuillText()
      .then((text) => {
        setQuillValue(text);
      })
      .catch((err) => {
        setModalText("Error fetching notes");
        setModalTitle("Error");
        setModalShown(true);
      });
  }, []);
  // Random component
  const Completionist = () => <span>Countdown completed!</span>;

  // Renderer callback with condition
  const renderer = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
  }: {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
    completed: boolean;
  }) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <span>
          {days} days {hours} hours {minutes} minutes {seconds} seconds
        </span>
      );
    }
  };
  return (
    <BaseScreen
      modalShown={showModal}
      modalTitle={modalTitle}
      modalText={modalText}
      modalHandler={handleModal}
    >
      <Row>
        <Col>
          <div className="card card-count">
            <Carousel pause="hover">
              {countdowns.map((countdown) => (
                <Carousel.Item key={countdown.name}>
                  <img
                    className="d-block w-100"
                    src={`http://api.abdash.xyz/countdown/${countdown.fileId}`}
                    alt={countdown.name}
                    height="100%"
                    width="100%"
                  />
                  <Carousel.Caption className="carouselCaption">
                    <h3>{countdown.name}</h3>
                    <div>
                      <Countdown
                        date={countdown.time}
                        renderer={renderer}
                      ></Countdown>
                    </div>
                  </Carousel.Caption>
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
        </Col>
        <Col>
          <div className="card">
            <ReactQuill
              theme="bubble"
              value={quillValue}
              onChange={(content) => {
                setQuillValue(content);
                apiService.putQuillText(content).catch((err) => {
                  setModalText("Error updating notes");
                  setModalTitle("Error");
                  setModalShown(true);
                });
              }}
              placeholder={"Notes"}
            />
          </div>
        </Col>
      </Row>
    </BaseScreen>
  );
};
