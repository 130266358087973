import React, { FunctionComponent, useEffect, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { apiService } from "../../utils/ApiService";
import "./LoginScreen.scss";

export const LoginScreen: FunctionComponent = () => {
  const [open, setOpen] = useState<boolean>(true);
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  useEffect(() => {
    apiService
      .ping()
      .then(() => {
        setLoggedIn(false);
      })
      .catch((err) => {
        setLoggedIn(false);
      });
  }, []);

  const loginFunction = () => {
    setOpen(false);
    apiService
      .login(username, password)
      .then((response: boolean) => {
        setLoggedIn(response);
        setOpen(true);
      })
      .catch((err) => {
        setLoggedIn(false);
        setOpen(true);
      });
  };

  return loggedIn ? (
    <Redirect to="/"></Redirect>
  ) : (
    <div className="background">
      <div className="context">
        <h1>Welcome</h1>
        {open ? (
          <Form className="formWrapper">
            <Form.Group className="lg-3" controlId="formBasicUsername">
              <Form.Control
                type="text"
                placeholder="Username"
                value={username}
                onChange={(props) => setUsername(props.target.value)}
              />
            </Form.Group>
            <Form.Group className="lg-3" controlId="formBasicPassword">
              <Form.Control
                type="password"
                placeholder="Password"
                value={password}
                onChange={(props) => setPassword(props.target.value)}
              />
            </Form.Group>
            <Button
              className="loginButton"
              variant="custom"
              onClick={loginFunction}
            >
              Login
            </Button>
          </Form>
        ) : (
          <Spinner animation="grow"></Spinner>
        )}
      </div>
      <div className="area">
        <ul className="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
    </div>
  );
};
