import React, { FunctionComponent } from "react";
import { Button, Nav, Navbar } from "react-bootstrap";
import "./NavigationBar.scss";

type NavigationBarProps = {
  logoutFunction: () => void;
};

export const NavigationBar: FunctionComponent<NavigationBarProps> = ({
  logoutFunction,
}) => {
  return (
    <Navbar id="navbar" variant="light" bg="primary" expand="lg">
      <Navbar.Brand href="/" className="mr-sm-5 name">
        {"A&B Dashboard"}
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse
        id="basic-navbar-nav"
        className="justify-content-end"
      >
        <Nav className="mr-auto">
          {/* <Nav.Link className="label" href="/journal">
            <i className="fa fa-book-open fa-fw mr-sm-1"></i>
            Journal
          </Nav.Link> */}
        </Nav>
        <Button className="logoutButton" variant="danger" onClick={logoutFunction}>
          Log out
        </Button>
      </Navbar.Collapse>
    </Navbar>
  );
};
