import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Container } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { CustomModal } from "../../components/custom-modal/CustomModal";
import { NavigationBar } from "../../components/navigation-bar/NavigationBar";
import { apiService } from "../../utils/ApiService";

interface BaseScreenProps {
  modalShown?: boolean;
  modalTitle?: string;
  modalText?: string;
  modalHandler?: () => void;
  refresh?: boolean;
}

export const BaseScreen: FunctionComponent<BaseScreenProps> = ({
  modalShown,
  modalTitle,
  modalText,
  children,
  modalHandler,
  refresh,
}) => {
  const [lastRefresh, setLastRefresh] = useState(false);
  const showModal = modalShown || false;
  const titleModal = modalTitle || "";
  const textModal = modalText || "";
  const handleModal = modalHandler || (() => {});

  const [isAuth, setIsAuth] = useState<boolean>(true);

  const getInfo = useCallback(() => {
    apiService
      .ping()
      .then((response: boolean) => {
        setIsAuth(response);
      })
      .catch((err) => {
        setIsAuth(false);
      });
  }, []);

  useEffect(() => {
    getInfo();
  }, [getInfo]);

  const logoutFunction = () => {
    apiService
      .logout()
      .then(() => {
        setIsAuth(false);
      })
      .catch((err) => {
        setIsAuth(false);
      });
  };

  if (refresh !== undefined && lastRefresh !== refresh) {
    setLastRefresh(refresh);
    getInfo();
  }

  return isAuth ? (
    <>
      <NavigationBar logoutFunction={logoutFunction} />
      <Container fluid>{children}</Container>
      <CustomModal
        title={titleModal}
        show={showModal}
        modalHandler={handleModal}
        body={textModal}
      ></CustomModal>
    </>
  ) : (
    <Redirect to="/login" />
  );
};
