import axios, { AxiosInstance, AxiosResponse } from 'axios';

export type Countdown = {
    time: number;
    name: string;
    fileId: number;
}

class ApiService {

    private axiosInstance: AxiosInstance;
    constructor(baseURL = 'https://api.abdash.xyz') {
        this.axiosInstance = axios.create({
            baseURL,
            withCredentials: true,
        });
    }

    private static responseBody<T>(response: AxiosResponse<T>): T {
        return response.data;
    }

    private async get<T>(url: string): Promise<T> {
        const response = await this.axiosInstance.get<T>(url);
        return ApiService.responseBody<T>(response);
    }
    private async post<T>(url: string, body?: unknown): Promise<T> {
        const response = await this.axiosInstance.post<T>(url, body);
        return ApiService.responseBody<T>(response);
    }
    private async patch<T>(url: string, body?: unknown): Promise<T> {
        const response = await this.axiosInstance.patch<T>(url, body);
        return ApiService.responseBody<T>(response);
    }
    private async delete<T>(url: string): Promise<T> {
        const response = await this.axiosInstance.delete<T>(url);
        return ApiService.responseBody<T>(response);
    }

    public login(username: string, password: string): Promise<boolean> {
        return this.post<boolean>('/auth/login', { username, password });
    }

    public ping(): Promise<boolean> {
        return this.get<boolean>('/auth/ping');
    }

    public logout(): Promise<void> {
        return this.post<void>('/auth/logout');
    }

    public async getCountdowns(): Promise<Countdown[]> {
        return this.get("/countdown");
    }

    public async addCountdown(countdown: Countdown): Promise<void> {
    }

    public async getQuillText(): Promise<string> {
        const { content } = await this.get("/note");
        return content;
    }

    public async putQuillText(content: string): Promise<void> {
        return this.post("/note", { content });
    }
}

export const apiService = new ApiService();
